<template>
  <div class="dashboard"> 

    <div class="dashboard-header">
      <form class="search search--filter" @submit.prevent="keywordsSearch()">
          <div class="search__wrapper">
            <input
              class="search__search search__search--active"
              ref="search"
               
              type="text"
              v-model="searchKeywords"
            />
            <div class="search__icon"></div> 
            <div class="search__splitter"></div>
          </div>
          <div
            class="search__filter"
            ref="filterToggler"
            @click="showSearchFilter = !showSearchFilter"
          ></div>
          <SearchFilter
            v-if="showSearchFilter"
            :filterToggler="$refs.filterToggler"
            :toggleSearchFilter="
              (state) => {
                showSearchFilter = state;
              }
            "
          />
        </form>

      <button
        type="button"
        class="button button--default"
        @click="showAddCustomerModal()"
        v-if="$checkRolePermission('customer_create')"
      >
        Add Customer
      </button>
    </div>

    <div v-if="!other" class="pendencies other-pendencies" style="margin-top: 100px;">
        <div v-if="loading" style="height: 300x;">
          <Loading  />
        </div>
        
        <div v-else>
          <div class="notes-title ">
            <div class="notes-title-img">
              <img src="../assets/icons/default/pendencies_icon_grey.svg"> 
            </div>
            
            <h2>Pendencies / Notes</h2> 
          </div>

          <div class="container-wrapper" v-if="dashboardSales.pendencies && dashboardSales.pendencies.length > 0">
            <div 
              class="notes-container"
              :class="{'pendency-container-active': pendency.agent.id === activeAgent}"
              v-for="pendency in dashboardSales.pendencies"
              :key="pendency.id"
            >
              <div class="all-pendencies-about">
                <div class="sender-info-wrapper">
                  <div class="sender-info">
                    <div class="all-pendencies-name-img"><p>{{ getInitials(pendency.agent.first_name, pendency.agent.last_name) }}</p></div>
                    <p>{{pendency.agent.first_name}} {{pendency.agent.last_name}}</p>
                  </div>

                  <div class="long-arrow-img">
                    <img src="../assets/icons/default/long_arrow.svg">
                  </div>

                  <div class="sender-info">
                      <div class="all-pendencies-name-img"><p>{{ getInitials(pendency.customer.first_name, pendency.customer.last_name) }}</p></div>
                      <p>{{pendency.customer.first_name}} {{pendency.customer.last_name}}</p>
                  </div>
                </div>


                <div class="sender-info" >
                  <div class="all-pendencies-name-img"><p>{{ getInitials(pendency.customer.first_name, pendency.customer.last_name) }}</p></div>
                  <p>{{pendency.customer.first_name}} {{pendency.customer.last_name}}</p>
                </div>
  

                <div class="all-pendencies-date-actions">
                  <div class="pendency-date"> 
                    <div class="icon icon__date"></div>
                    <p>{{formatDate(pendency.deadline)}}</p>
                  </div>
                  <div class="action-btns">
                    <a v-if="pendency.agent.phone" :href="'tel:' + pendency.agent.phone">
                      <div class="icon icon__phone--active"></div>  
                    </a>
                    <div v-else class="icon icon__phone"></div>

                    <a v-if="pendency.agent.email" :href="'mailto:' + pendency.agent.email">
                      <div class="icon icon__mail--active"></div>
                    </a>
                    <div v-else class="icon icon__mail"></div>

                 
                    <div @click="openPendencyFilesModal(pendency.id, pendency.files, pendency.customer.id)">
                      <div class="icon icon__pdf--active tool-icon" v-if="pendency.files && pendency.files.length > 0"></div>
                      <div class="icon icon__pdf tool-icon" v-else></div>
                    </div> 
                    <div class="icon icon__add tool-icon" @click="showAddReplyPendencyModal(pendency.policy.id, pendency.id, pendency.agent)"></div>
                   
                  </div>
                </div>
              </div>
              <div class="pendency-content"> 
                <div class="pendency-msg">
                  <p>{{pendency.description}}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else style="height: 500px;">
            <NoData style="transform: scale(0.8);" :type="'pendencies'" />
          </div>
        </div>
    </div>

    <div v-else>
      <div class="statistics-list">

        <div class="statistics-item"> 
          <div v-if="loading">
            <Loading  />
          </div>

          <div v-else>
            <div class="statistics-img potential-pendencies"><img src="../assets/icons/default/potentialcustomer_icon.svg"></div>
            <div class="statistics-content">        
              <h2>Total<br> Potenzial</h2>
              <h1>{{dashboardSales.totalPotentialPolicies}}</h1>
            </div> 
          </div>
        </div>
    
        <div class="statistics-item">
          <div v-if="loading">
            <Loading  />
          </div>
          
          <div v-else>
            <div class="statistics-img customers"><img src="../assets/icons/default/users_icon.svg"></div>
            <div class="statistics-content">
              <h2>Customers</h2>
              <h1>{{dashboardSales.totalCustomers}}</h1>
            </div>

            <!-- <div class="statistics-badge statistics-badge__positive"><p>+ 2.3%</p></div> -->
          </div>
        </div>

        <div class="statistics-item">
          <div v-if="loading">
            <Loading  />
          </div>
          
          <div v-else>
            <div class="statistics-img contracts-process"><img src="../assets/icons/default/contact_icon_left_menu.svg"></div> 
            <div class="statistics-content">
              <h2>Contracts<br> in Process</h2>
              <h1>{{dashboardSales.totalContractsInProcess}}</h1>
            </div>
          
            <!-- <div class="statistics-badge statistics-badge__negative"><p>- 1.6%</p></div> -->
          </div>
        </div>

      </div>

      <div class="notes-list">
        
        <div class="pendencies sales-pendencies">
          <div v-if="loading" style="height: 300px;">
            <Loading  />
          </div>
          
          <div v-else style="overflow-y: scroll;">
            <div class="notes-title ">
              <div class="notes-title-img">
                <img src="../assets/icons/default/pendencies_icon_grey.svg"> 
              </div>
              
              <h2>Pendencies / Notes</h2> 
            </div>

            <div class="container-wrapper" v-if="dashboardSales.pendencies && dashboardSales.pendencies.length > 0 ">
              <div 
                class="notes-container"
                v-for="pendency in dashboardSales.pendencies"
                :key="pendency.id"
              >
                <div class="sales-pendency-about">
                  <div class="sales-pendency-creator">
                    <div class="name-img">
                      <p v-if="pendency.agent">{{ getInitials(pendency.agent.first_name, pendency.agent.last_name) }}</p>
                    </div>
                    <p v-if="pendency.agent">{{pendency.agent.first_name}} {{pendency.agent.last_name}}</p>
                  </div>
                  <div class="sales-pendency-date-wrapper">
                    <div class="pendency-date"> 
                      <div class="icon icon__date"></div>
                      <p v-if="pendency.deadline">{{formatDate(pendency.deadline)}}</p>
                    </div>
                    <div class="action-btns">
                      <a v-if="pendency.agent.phone" :href="'tel:' + pendency.agent.phone">
                        <div class="icon icon__phone--active"></div>  
                      </a>
                      <div v-else class="icon icon__phone"></div>

                      <a v-if="pendency.agent.email" :href="'mailto:' + pendency.agent.email">
                        <div class="icon icon__mail--active"></div>
                      </a>
                      <div v-else class="icon icon__mail"></div>
                    </div>
                  </div>
                </div>
                <div class="pendency-content">
                  <div class="pendency-content-actions">
                    <div class="pendency-receiver">
                      <div class="name-img">
                        <p v-if="pendency.customer">{{ getInitials(pendency.customer.first_name, pendency.customer.last_name) }}</p>
                      </div>
                      <p v-if="pendency.customer">{{pendency.customer.first_name}} {{pendency.customer.last_name}}</p>
                    </div>
                    <div class="action-btns">
                      <div @click="openPendencyFilesModal(pendency.id, pendency.files, pendency.customer.id)">
                        <div class="icon icon__pdf--active tool-icon" v-if="pendency.files && pendency.files.length > 0"></div>
                        <div class="icon icon__pdf tool-icon" v-else></div>
                      </div> 
                      <div class="icon icon__add tool-icon" @click="showAddReplyPendencyModal(pendency.policy.id, pendency.id, pendency.agent, pendency.customer.id)"></div>
                    </div>
                  </div>
                  <div class="pendency-msg">
                    <p>{{pendency.description}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="height: 500px;">
              <NoData style="transform: scale(0.8);" :type="'pendencies'" />
            </div>
          </div>
        </div>


        <div class="policies">
          <div v-if="loading" style="height: 300px;">
            <Loading  />
          </div>
          
          <div v-else style="overflow-y: scroll;">
            <div class="notes-title policies-title">
              <div class="notes-title-img">
                <img src="../assets/icons/default/potentialcustomer_icon_grey.svg"> 
              </div>
              
              <h2>Potencial Polices</h2>
            </div>

            <div class="container-wrapper" v-if="dashboardSales.potentialPolicies && dashboardSales.potentialPolicies.length > 0">
              <div 
                class="notes-container policy-container"
                v-for="policy in dashboardSales.potentialPolicies"
                :key="policy.id"
              >
                <div class="policy-about">
                  <div class="policy-creator">
                    <div class="name-img policy-name-img">
                      <p v-if="policy.agent">{{ getInitials(policy.agent.first_name, policy.agent.last_name) }}</p>
                    </div>
                    <p v-if="policy.agent">{{policy.agent.first_name}} {{policy.agent.last_name}}</p>
                  </div> 
                  <div class="sales-pendency-date-wrapper ">
                    <div class="pendency-date">
                      <div class="icon icon__date"></div>
                      <p v-if="policy.date">{{formatDate(policy.date)}}</p></div>
                  <!-- </div>
                  
                  
                  <div class="pendency-category-wrapper"> -->
                    <div class="policy-category pendency-date">
                      <div class="icon icon__insurance"></div>
                      <p>Category <span>{{policy.category.code}}</span></p></div>

                      <div @click="openPolicyFilesModal(policy.id, policy.files, policy.customer_id)">
                        <div v-if="policy.files && policy.files.length > 0" class="icon icon__pdf--active tool-icon"></div>
                        <div v-else class="icon icon__pdf tool-icon"></div>
                      </div>
                    
                  </div>
                  
                </div>
                <div class="policy-msg">
                  <p>{{ policy.text }}</p>
                </div>
              </div>
            </div> 
            <div v-else style="height: 500px;">
              <NoData style="transform: scale(0.8);" :type="'potential-policies'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NoData from "@/partials/NoData";
import Loading from "@/partials/Loading";
import SearchFilter from "@/partials/SearchFilter";

export default {
  name: "Dashboard",
  computed: {
    ...mapGetters([ 
      'dashboardSales',
      'loggedUser',
    ]),
  },
  components: {
    SearchFilter,
    NoData,
    Loading
  },
  data(){
    return{
      other: true,

      loading: true,

      searchKeywords: "",
      showSearchFilter: false,
      selectedAgents: [],
      selectedCompanyTypes: [],
      selectedCompanies: [],
      selectedBranchCategories: [],
      selectedPolicyStatuses: [],
      searchPlaceholder: "",
      hasTerminations: '', 
 

      startDateFrom:"",
      startDateTo: "",

      endDateFrom:"",
      endDateTo: "",

      submitDateFrom:"",
      submitDateTo: "",

      paymentStatus: '',

      activeAgent: ''
    }
  },
  async created() { 
    if (Object.keys(this.$route.query).length > 0) {
      
      this.$router.replace({
        path: this.$route.path,  
        query: {}  
      });
    }


    if(this.loggedUser){
      this.activeAgent = this.loggedUser.id
    }
    
    
    await this.$store.dispatch("getSearchCustomerForms");
    this.fetchSalesData(); 
  },
  methods:{
    showAddCustomerModal() {
      this.$store.commit("setShowAddCustomerModal", true);
      this.$store.commit("setIsAddingCustomer", true);
    },

    async keywordsSearch() {
      this.$store.commit("setCustomersLoading", true);
      await this.$store.dispatch("searchCustomers", {
        search: this.searchKeywords,
        agents: this.selectedAgents,
        companyTypes: this.selectedCompanyTypes,
        companies: this.selectedCompanies,
        branchCategories: this.selectedBranchCategories,
        policyStatuses: this.selectedPolicyStatuses,
        birthday: this.$route.query.birthday,
        zip: this.$route.query.zip,
        has_terminations: this.hasTerminations,

        startDateFrom:this.startDateFrom,
        startDateTo: this.startDateTo,

        endDateFrom:this.endDateFrom,
        endDateTo: this.endDateTo,

        submitDateFrom:this.submitDateFrom,
        submitDateTo: this.submitDateTo,

        payment_status: this.paymentStatus
      });

      this.$router.replace({ 
        path: "/customers",
        query: {
          page: 1,   
          search: this.searchKeywords,
          has_terminations: this.hasTerminations,
          payment_status: this.paymentStatus,
        },
      }); 

      this.$store.commit("setCustomersLoading", false);
    },

    async fetchSalesData(){
      this.loading = true;
      try {
        await this.$store.dispatch("getSalesDashboard");
        this.loading = false;
      } catch (error) { 
        this.$store.commit("setToast", {
          display: true,
          type: "error",
          message: error,
        });
      }
      
    },

    getInitials(firstName, lastName) {
      const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
      const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
      return `${firstInitial}${lastInitial}`;
    },

    formatDate(dateString) { 
      const [year, month, day] = dateString.split('-'); 
      return `${day}.${month}.${year}`;
    },


    showAddReplyPendencyModal(policyId, serviceId, agent, customerId) { 
      this.$store.commit("setPendencyForReply", true);
      this.$store.commit("setShowAddPendencyModal", true); 
      this.$store.commit("setPendencyReplyData", {
        policyId,
        serviceId,
        agent
      });

      this.$store.commit("setCustomerFromDashboard", customerId);
    },

    openPendencyFilesModal(pendencyId, pendencyFiles, customerId) { 
      this.$store.commit("setShowFilesEditModal", true);
      this.$store.commit("setFilesToEditId", pendencyId);
      this.$store.commit("setFilesToEdit", pendencyFiles); 
      this.$store.commit("setFilesEditMode", 'Pendencies'); 

      this.$store.commit("setFilesFromDashboard", true);
      this.$store.commit("setCustomerFromDashboard", customerId);
    }, 

    openPolicyFilesModal(policyId, policyFiles, customerId) {
      this.$store.commit("setPolicyFileId", policyId);
      this.$store.commit("setPolicyFiles", policyFiles);
      this.$store.commit("setPolicyFilesModalIsPotential", true);
      this.$store.commit("setShowPolicyFilesModal", true);

      this.$store.commit("setFilesFromDashboard", true);
      this.$store.commit("setCustomerFromDashboard", customerId);
    },
  }
};
</script>

<style scoped lang="scss">
$bottomHeight: 432px;
.dashboard {
  height: 100%;
  position: relative;

  &__top {
    margin-bottom: 20px;
    height: calc(100% - #{$bottomHeight} - 20px);
    overflow: auto;
  }

  &__bottom {
    height: $bottomHeight;
    display: flex;
  }
}

.dashboard-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 ; 
}

.dashboard-header .button--default{
  width: 200px;
}

.search {
  background-color: white; 
  width: 50%;
}

.search__wrapper{
  width: 100%;
}

.search-filter{
  z-index: 5 !important;
  top: 60px !important;
  right: initial !important;
  left: 0 !important;
}

.search--filter .search__search--active, 
.search--filter .search__search:focus {
    width: 90% !important; 
}


.statistics-list,
.notes-list{  
  width: 100%; 
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 50px 0; 
  padding-bottom: 20px;
}

.statistics-item{ 
  width: 32%; 
  box-sizing: border-box;
  padding: 15px;
  background-color: #FFFFFF;
  box-shadow: 0px 5px 10px #0000000D;
  position: relative;
  padding-top: 60px;
  border-radius: 10px;
  transition: 0.3s ease;
}

.statistics-item:hover{
  box-shadow: none;
  margin-top: -10px;
}

.statistics-img{
  width: 70px;
  height: 70px;
  
  box-shadow: 0px 5px 10px #00000014;
  border-radius: 50%;
  position: absolute;
  top: -35px;
  left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    width: 50%;
    height: 50%;
  }
}

.potential-pendencies{
  background-color: #F9FAFC;
}

.customers{ 
  background-color: #FEF3E1;
}

.contracts-process{ 
  background-color: #DBEDF6;
}

.statistics-content{
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 90%;
  margin-right: auto;

  h1{
    font-family: var(--muli-text-black);
    color: #22DB8E;
    font-size: 74px;
  }

  h2 {
    font-family: var(--muli-text-black);
    color: #B9C2CD;
    font-size: 24px;
  }
}


.statistics-badge{
  padding: 10px;
  border-radius: 10px;
  
  position: absolute;
  top: 12px;
  right: 12px;

  p{
    font-family: var(--muli-text-bold);
    font-size: 18px;
  }

  &__positive{
    background-color: #E5F9F0;

    p{
      color: #22DB8E;
    }
  }

  &__negative{
    background-color: #FBE8E8;

    p{
      color: #FF3333;
    }
  }
}

.other-pendencies{
  width: 100% !important;
}

.sender-info-wrapper, .sender-info{
  display: flex; 
  align-items: center;  
  gap: 14px;
}

.sender-info{ 
  gap: 10px;
  p{
    font-size: 16px;
  }
}

.long-arrow-img{
  width: 22px;
  height: 22px;
  img{
    width: 100%;
  }
}

.pendency-container-active{ 
  box-shadow: 0px 0px 10px #0000000D;
  background-color: #E5F9F0 !important;
  border: 1px solid #22DB8E !important;
}

.all-pendencies-about{
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between; 
}

.all-pendencies-date-actions{
  display: flex;
  align-items: center;
  gap: 50px;
}


.container-wrapper{
  max-height: 800px;
  padding: 15px;
}

.pendencies,
.policies{
  width: 49%;
  box-sizing: border-box;
  position: relative;
  // padding: 15px;
  padding-top: 50px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 5px 10px #0000000D;
}

.notes-title{
  position: absolute;
  top: -35px;
  padding-left: 15px;

  
  display: flex;
  align-items: center;

  h2{
    font-size: 24px;
    background-color: #E1E8FE;
    color: #7F8FA4;
    padding: 8px 12px; 
    padding-left: 27px;
    z-index: 1;
    margin-left: -15px;
    border-radius: 10px;
    font-family: var(--muli-text-black);
  }
}

.notes-title-img{
  z-index: 2;
  width: 70px;
  height: 70px;
  box-shadow: 0px 5px 10px #00000014;
  background-color: #E1E8FE;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  img{
    width: 50%;
  }
}


.notes-container{
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px #0000000D;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
  color: #363636;
  transition: 0.3s ease;
}

.notes-container:hover{
  box-shadow: 0px 0px 20px #00000026;
}

.notes-container:last-child{
  margin-bottom: 0;
}

.sales-pendency-about,
.pendency-content-actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin-bottom:15px;
}

.pendency-content-actions{
  padding: 0;
}

.action-btns{
  display: flex;
  align-items: center;
  gap: 15px;
}

.sales-pendency-creator,
.pendency-receiver{
  width: 50%; 
  display: flex;
  align-items: center;
  gap: 10px;

  p{
    font-size: 16px;
  }
}



.pendency-date{
  background-color: #F5F9FD;
  border-radius: 10px;
  padding:2px 10px;

  display: flex;
  align-items: center;
  gap: 5px;

  p{
    color: #7F8FA4;
    font-size: 14px;
    font-family: var(--muli-text-semi-bold);
  }

  span{
    font-size: 16px;
    font-family: var(--muli-text-bold);
  }
}

.pendency-content{
  background-color: #F5F9FD;
  padding: 15px;
  border-radius: 10px;
  
  p{
    font-size: 14px;
  }
}

.name-img,
.all-pendencies-name-img{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #76B4FA;
  display: flex;
  border: 2px solid #22DB8E;
  align-items: center;
  justify-content: center;

  p{
    color: white;
    font-family: var(--muli-text-bold);
    font-size: 10px;
  }

  img{
    width: 100%;
  }
}

.all-pendencies-name-img{
  width: 30px;
  height: 30px;
}

.policy-container{
  margin-top: 20px;
  margin-bottom: 50px;
}

 

.policy-about{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
  margin-bottom: 16px;
}

.policy-creator{
  position: absolute;
  top: -35px;   
  left: 0%;   
  display: flex;
  flex-direction: column;
  align-items: center;

  p{
    font-size: 16px;
  }
}


.sales-pendency-date-wrapper,
.pendency-category-wrapper{ 
  display: flex;
  gap: 20px;
  align-items: center; 
  margin-left: auto;
}

.policy-msg{
  width: 100%;

  p{
    font-size: 14px;
    color: #707070;
  }
}


.policy-name-img{
  width: 40px;
  height: 40px;
  margin-bottom: 5px;

  p{
    font-size: 12px;
  }
}


.dashboard-icon{
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  
}


.icon--pendencies{
  background-image: url("../assets/icons/default/modal_pendencies_icon.svg")  ;
}





/* 2304-1470 */
@media (min-width: 2304px) {
  .pendencies, .policies {
    padding-top: 60px;
  }

  .statistics-content h2 { 
    font-size: 28px;
  }

  .sales-pendency-creator p, .pendency-receiver p {
    font-size: 18px;
  }

  .pendency-msg p {
    font-size: 16px;
  }

  .pendency-date {
    p {
      font-size: 16px;
    }

    span {
      font-size: 18px;
    }
  }

  .name-img {
    width: 40px;
    height: 40px;

    p{
      font-size: 12px;
    }
  }

  .policy-container {
    margin-top: 30px;
  }

  .policy-about { 
    margin-bottom: 24px;
  }

  .policy-msg{ 
    p {
      font-size: 16px;
    }
  }
 
  .policy-creator {
    top: -40px;

    p {
      font-size: 18px;
    }
  }

  .policy-name-img {
    width: 45px;
    height: 45px;

    p {
      font-size: 14px;
    }
  }
}
 

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) { 
  //other pendencies
  .sender-info p{
    font-size: 18px; 
  }

  .all-pendencies-name-img{
    width: 35px;
    height: 35px;
    p{
      font-size: 12px;
    }
  }

  .long-arrow-img{
    width: 25px;
    height: 25px; 
  }
  
  //stats
  .statistics-img {
    width: 80px;
    height: 80px;
    top: -45px;
  }

  .statistics-content h2 { 
    font-size: 30px;
  }

  .statistics-content h1 {
    font-size: 76px;
  }

  .statistics-badge p {
    font-size: 20px;
  }

  // lists
  .pendencies, .policies {
    padding-top: 70px;
  }

  .notes-title {
    top: -40px;
  }

  .notes-title-img {
    width: 80px;
    height: 80px;
  }

  .notes-title h2 {
    font-size: 28px;
  }

  .sales-pendency-creator p, .pendency-receiver p {
    font-size: 20px;
  }

  .pendency-msg p {
    font-size: 18px;
  }

  .pendency-date {
    p {
      font-size: 18px;
    }

    span {
      font-size: 20px;
    }
  }

  .name-img {
    width: 45px;
    height: 45px;

    p{
      font-size: 14px;
    }
  }

  .policy-container {
    margin-top: 40px;
  }

  .policy-about { 
    margin-bottom: 30px;
  }

  .policy-msg{ 
    p {
      font-size: 18px;
    }
  }
 
  .policy-creator {
    top: -50px;

    p {
      font-size: 20px;
    }
  }

  .policy-name-img {
    width: 50px;
    height: 50px;

    p {
      font-size: 16px;
    }
  }
}
 

/* 2880-1670 */
@media(min-width: 2880px) {
  //other pendencies
  .sender-info p{
    font-size: 20px; 
  }

  .all-pendencies-name-img{
    width: 40px;
    height: 40px;
    p{
      font-size: 14px;
    }
  }

  .long-arrow-img{
    width: 28px;
    height: 28px; 
  }

  //stats
    .statistics-img {
    width: 90px;
    height: 90px;
    top: -45px;
  }

  .statistics-content h2 { 
    font-size: 34px;
  }

  .statistics-content h1 {
    font-size: 80px;
  }

  .statistics-badge p {
    font-size: 22px;
  }

  // lists
  .pendencies, .policies {
    padding-top: 80px;
  }

  .notes-title {
    top: -45px;
  }

  .notes-title-img {
    width: 85px;
    height: 85px;
  }

  .notes-title h2 {
    font-size: 30px;
  }
 
  .sales-pendency-creator p, .pendency-receiver p {
    font-size: 22px;
  }

  .pendency-msg p {
    font-size: 20px;
  }

  .pendency-date {
    p {
      font-size: 20px;
    }

    span {
      font-size: 22px;
    }
  }

  .name-img {
    width: 50px;
    height: 50px;

    p{
      font-size: 16px;
    }
  }

  .policy-container {
    margin-top: 50px;
  }

  .policy-about { 
    margin-bottom: 40px;
  }

  .policy-msg{ 
    p {
      font-size: 20px;
    }
  }
 
  .policy-creator {
    top: -60px;

    p {
      font-size: 22px;
    }
  }

  .policy-name-img {
    width: 55px;
    height: 55px;

    p {
      font-size: 18px;
    }
  }
}
 

/*3200-1800*/
@media(min-width: 3200px) {
  //other pendencies
  .sender-info p{
    font-size: 24px; 
  }

  .all-pendencies-name-img{
    width: 45px;
    height: 45px;
    p{
      font-size: 14px;
    }
  }

  .long-arrow-img{
    width: 35px;
    height: 35px; 
  }

  //stats  
  .statistics-content h2 { 
    font-size: 36px;
  }

  .statistics-content h1 {
    font-size: 82px;
  }

  .statistics-badge p {
    font-size: 22px;
  }

  // lists 
  .pendencies, .policies {
    padding-top: 90px;
  }
 
  .sales-pendency-creator p, .pendency-receiver p {
    font-size: 22px;
  }

  .pendency-msg p {
    font-size: 20px;
  }

  .pendency-date {
    p {
      font-size: 20px;
    }

    span {
      font-size: 22px;
    }
  }

  .name-img {
    width: 50px;
    height: 50px;

    p{
      font-size: 16px;
    }
  }

  .policy-container {
    margin-top: 50px;
  }

  .policy-about { 
    margin-bottom: 40px;
  }

  .policy-msg{ 
    p {
      font-size: 20px;
    }
  }
 
  .policy-creator {
    top: -60px;

    p {
      font-size: 22px;
    }
  }

  .policy-name-img {
    width: 55px;
    height: 55px;

    p {
      font-size: 18px;
    }
  }
}
 

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
  //other pendencies
  .sender-info p{
    font-size: 28px; 
  }

  .all-pendencies-name-img{
    width: 50px;
    height: 50px;
    p{
      font-size: 16px;
    }
  }

  .long-arrow-img{
    width: 40px;
    height: 40px; 
  }

  //stats
    .statistics-img {
    width: 100px;
    height: 100px; 
  } 
 
  .statistics-content h2 { 
    font-size: 40px;
  }

  .statistics-content h1 {
    font-size: 86px;
  }

  .statistics-badge p {
    font-size: 24px;
  }

  // lists 
  .notes-title-img {
    width: 95px;
    height: 95px;
  }

  .notes-title h2 {
    font-size: 34px;
  }

  .pendencies, .policies {
    padding-top: 100px;
  }
 
  .sales-pendency-creator p, .pendency-receiver p {
    font-size: 28px;
  }

  .pendency-msg p {
    font-size: 24px;
  }

  .pendency-date {
    p {
      font-size: 24px;
    }

    span {
      font-size: 26px;
    }
  }

  .name-img {
    width: 60px;
    height: 60px;

    p{
      font-size: 18px;
    }
  }

  .policy-container {
    margin-top: 60px;
  }

  .policy-about { 
    margin-bottom: 50px;
  }

  .policy-msg{ 
    p {
      font-size: 24px;
    }
  }
 
  .policy-creator {
    top: -60px;

    p {
      font-size: 24px;
    }
  }

  .policy-name-img {
    width: 65px;
    height: 65px;

    p {
      font-size: 22px;
    }
  }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
  //stats
  // .statistics-item {
  //   padding-top: 80px;
  // }

  .statistics-img {
    width: 65px;
    height: 65px;
    top: -30px;
  }

  .statistics-content h1 { 
    font-size: 60px;
  }

  .statistics-badge p {
    font-size: 16px;
  }

  //notes
  .notes-title-img {
    width: 65px;
    height: 65px;
  }

  .notes-title h2 {
    font-size: 22px;
  }
}

/*1688-1080*/
@media(max-width: 1688px) {
}

/*1680-1080*/
@media(max-width: 1680px) {
  //stats
  // .statistics-item {
  //   padding-top: 70px;
  // }

  .statistics-img {
    width: 60px;
    height: 60px;
    top: -30px;
  }

  .statistics-content {
    width: 100%;
  }

  .statistics-content h1 { 
    font-size: 50px;
  }

  .statistics-badge p {
    font-size: 14px;
  }

  .statistics-content h2 {
    font-size: 22px;
  }

  //notes
  .notes-title {
    top: -30px;
  }

  .notes-title-img {
    width: 60px;
    height: 60px;
  }
  
  .notes-title h2 {
    font-size: 20px;
  }

  .sales-pendency-date-wrapper, .pendency-category-wrapper {
    gap: 10px;
  }

  .pendency-date p {
    font-size: 12px;
  }

  .pendency-date span {
    font-size: 14px;
  }

  .sales-pendency-creator p, .pendency-receiver p, .policy-creator p {
    font-size: 14px;
  }

  .name-img p { 
    font-size: 10px;
  }

  .policy-name-img p {
    font-size: 12px;
  }
}

/*1640-1080*/
@media(max-width: 1640px) {
//   .other-pendencies {
//     width: 70% !important;
// }
    //other pendencies
    .sender-info p{
    font-size: 14px; 
  }

  .all-pendencies-name-img{
    width: 25px;
    height: 25px;
    p{
      font-size: 10px;
    }
  }

  .long-arrow-img{
    width: 20px;
    height: 20px; 
  }

  .all-pendencies-date-actions { 
    gap: 20px;
}
}

/* 1600 - 757 */
@media (max-width: 1600px) {
}

/*1536-864*/
@media(max-width:1536px) {
  .all-pendencies-about{
    flex-wrap: wrap;
  }

  .all-pendencies-date-actions{
    width: 100%; 
    margin-top: 16px; 
  }

  .sales-pendency-about,
  .policy-about{
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
  }

  .sales-pendency-date-wrapper{
    margin-left: 0;
    margin-top: 15px; 
    width: 100%;
    gap: 0;
    justify-content: space-between;
  }

  .policy-container {
    margin-top: 0px;
  }

  .policy-creator{
    position: relative;
    top: 0;
    flex-direction: row;
    gap: 10px;
  }
  
  .policy-name-img{
    margin: 0;
  }

  .name-img {
    width: 35px;
    height: 35px;

    p {
        font-size: 10px;
    }
  }

  .pendency-date p {
    font-size: 14px;
  }
 
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
}

/*1366-638*/
@media (max-width:1366px) {
  .statistics-img {
    width: 50px;
    height: 50px;
    top: -25px;
  }

  .statistics-content h1 {
    font-size: 36px;
  }

  .statistics-content h2 {
    font-size: 18px;
  }

  .statistics-badge p {
    font-size: 12px;
  }


  .notes-title {
    top: -25px;
  }

  .notes-title-img {
    width: 50px;
    height: 50px;
  }

  .notes-title h2 {
    font-size: 18px;
  }

  .notes-container {
    padding: 10px;
  }
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
}

/* 1200-1920 */
@media (max-width:1200px) {
  .statistics-item {
    width: 49%;
    margin-bottom: 50px;
  }

  .statistics-item:last-child{
    margin-bottom: 0;
  }

  .pendencies, .policies {
    width: 100%;
  }

  .pendencies{
    margin-bottom: 80px;
  }
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
  .sender-info-wrapper{
    width: 100%;
    margin-bottom: 16px;
  }
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
  .statistics-item {
    width: 100%;
  }
}

/* 962x601 */
@media(max-width: 962px) {
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
  .other-pendencies {
    width: 100% !important;
  }
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
  .statistics-list, .notes-list {
    width: 100%;
  }

  .statistics-img {
    width: 60px;
    height: 60px;
    top: -30px;
  }

  .statistics-content{ 
    h2 {
      font-size: 26px;

      br{
        display: none;
      }
    }
  }

  .statistics-content h1 {
    font-size: 46px;
  }

  .statistics-badge {
    padding: 8px 10px;

    p{
      font-size: 14px;
    }
  }

  //notes
  .notes-title {
    top: -30px;
  }

  .notes-title-img {
    width: 60px;
    height: 60px;
  }

  .notes-title h2 {
    font-size: 22px;
  }

  .sales-pendency-creator p, .pendency-receiver p, .policy-creator p {
    font-size: 16px;
  }

  .name-img p{
    font-size: 12px;
  }
 
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
  .pendency-content-actions{
    flex-wrap: wrap;
  }

  .sales-pendency-creator, .pendency-receiver {
    width: 100%;
  }

  .pendency-content-actions .pendency-receiver {
    margin-bottom: 10px;
  }

  .pendency-content-actions .action-btns{
    margin-left: auto;
  }
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
  .sender-info p {
    font-size: 12px;
  }

  .all-pendencies-name-img p {
    font-size: 10px;
  }
}

/* 600x962 */
@media(max-width:600px) { 
  .sender-info-wrapper,
  .all-pendencies-date-actions{
    flex-wrap: wrap;
  }
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
  .statistics-content h2 {
    font-size: 24px;
  }

  .notes-title h2 {
    font-size: 20px;
  }

  .pendency-date p {
    font-size: 12px;
  }

}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
  .policy-about .sales-pendency-date-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  } 

  .policy-category {
    margin: 10px 0;
  }

  .sales-pendency-date-wrapper .icon {
    margin-left: auto;
  }

  .pendency-date p {
    font-size: 14px;
  }

  .statistics-content h2 {
        font-size: 22px;
    }
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
  .statistics-img {
    width: 55px;
    height: 55px;
    top: -26px;
  }

  .statistics-content h1 {
    font-size: 44px;
  }

  .statistics-badge p {
    font-size: 12px;
  }

  .notes-title {
    top: -27px;
    h2 {
      font-size: 18px;
    }
  }

  .notes-title-img {
    width: 55px;
    height: 55px;
  } 

}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
  .statistics-content h2 {
    font-size: 18px;
  }

  .statistics-content h1 {
    font-size: 38px;
  }


  .notes-title {
    top: -24px;
  }

  .notes-title h2 {
    font-size: 16px;
  }

  .notes-title-img {
    width: 50px;
    height: 50px;
  }

  .sales-pendency-creator p, .pendency-receiver p, .policy-creator p {
    font-size: 14px;
  }

  .pendency-date p {
    font-size: 12px;
  }

  .pendency-content p {
    font-size: 12px;
  }

  .name-img {
    width: 30px;
    height: 30px;
    p{
      font-size: 10px;
    }
  }

  .icon {
    height: 22px;
    width: 22px;
  }
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
  .statistics-content h2 {
    font-size: 16px;
  }

  .statistics-content h1 {
    font-size: 34px;
  }


  .notes-title {
    top: -22px;
  }

  .notes-title h2 {
    font-size: 14px;
  }

  .notes-title-img {
    width: 45px;
    height: 45px;
  }

  .sales-pendency-creator p, .pendency-receiver p, .policy-creator p {
    font-size: 14px;
  }

  .pendency-date p {
    font-size: 12px;
  }

  .pendency-content p {
    font-size: 12px;
  }

  .name-img {
    width: 30px;
    height: 30px;
    p{
      font-size: 10px;
    }
  }

  .icon {
    height: 22px;
    width: 22px;
  }
}
</style>
